<template>
  <div class="container round px-0 mb-5">
    <div class="page-header mb-1">
      <strong>รายการจ่ายเงิน</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />
      <div v-if="isAvailable" class="card-body p-3">
        <div>
          <button
            :disabled="resultStep!=='LoseTickets'"
            class="btn btn-sm"
            :class="resultStep==='LoseTickets'?'btn-success':'btn-outline-success'"
            @click="payWonMembers"
          >
            <i class="fas fa-hand-holding-usd"></i> จ่ายเงิน Member
          </button>
        </div>

        <div class="mt-3">
          <button
            :disabled="resultStep!=='payWonMember'"
            class="btn btn-sm"
            :class="resultStep==='payWonMember'?'btn-success':'btn-outline-success'"
            @click="findAgents"
          >
            สรุปยอด Agent
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='findAgent'"
            class="btn btn-sm"
            :class="resultStep==='findAgent'?'btn-success':'btn-outline-success'"
            @click="payAgents"
          >
            <i class="fas fa-hand-holding-usd"></i> จ่ายเงิน Agent
          </button>
        </div>

        <div class="mt-3">
          <button
            :disabled="resultStep!=='payAgent'"
            class="btn btn-sm"
            :class="resultStep==='payAgent'?'btn-success':'btn-outline-success'"
            @click="closeTickets"
          >
            ปิดโพย
          </button>
        </div>

        <div class="mt-3">
          <button
            :disabled="resultStep!=='closeTicket'"
            class="btn btn-sm"
            :class="resultStep==='closeTicket'?'btn-success':'btn-outline-success'"
            @click="closeItems('threeNumberTop')"
          >
            ปิดเลข 3 ตัวบน
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='closeItem-threeNumberTop'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-threeNumberTop'?'btn-success':'btn-outline-success'"
            @click="closeItems('threeNumberTode')"
          >
            ปิดเลข 3 ตัวโต๊ด
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='closeItem-threeNumberTode'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-threeNumberTode'?'btn-success':'btn-outline-success'"
            @click="closeItems('twoNumberTop')"
          >
            ปิดเลข 2 ตัวบน
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='closeItem-twoNumberTop'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-twoNumberTop'?'btn-success':'btn-outline-success'"
            @click="closeItems('twoNumberBottom')"
          >
            ปิดเลข 2 ตัวล่าง
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='closeItem-twoNumberBottom'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-twoNumberBottom'?'btn-success':'btn-outline-success'"
            @click="closeItems('runTop')"
          >
            ปิดเลขวิ่งบน
          </button>

          <span class="text-secondary"> > </span>
          <button
            :disabled="resultStep!=='closeItem-runTop'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-runTop'?'btn-success':'btn-outline-success'"
            @click="closeItems('runBottom')"
          >
            ปิดเลขวิ่งล่าง
          </button>
        </div>

        <div class="mt-3">
          <button
            :disabled="resultStep!=='closeItem-runBottom'"
            class="btn btn-sm"
            :class="resultStep==='closeItem-runBottom'?'btn-success':'btn-outline-success'"
            @click="closeRound"
          >
            ปิดงวดหวย
          </button>
        </div>
      </div>
      <div v-else class="card-body">
        <div class="alert alert-warning mb-0">ยังไม่ได้ออกผล</div>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import RoundCardHeader from './components/RoundCardHeader'
import { marketOpenBets } from "@/configs/market.config"
import PayHalfNumberList from './components/PayHalfNumberList'
import cryptoRandomString from 'crypto-random-string'
import TicketStatus from '@/views/components/TicketStatus'

export default {
  name: 'PayWonTickets',
  components: {
    RoundCardHeader,
    PayHalfNumberList,
    TicketStatus
  },
  data() {
    return {
      round: null,
      data: null,
      uniqueCode: null
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    items() {
      return this.data?.tickets || []
    },
    status() {
      return this.round?.status
    },
    isAvailable() {
      return ['Complete', 'Paid'].includes(this.status)
    },
    isPaid() {
      return this.status === 'Paid'
    },
    summary() {
      return this.items.reduce((total, item)=>{
        total.amount += item.summary.amount
        total.won += item.summary.won
        return total
      }, {
        amount: 0,
        won: 0
      })
    },
    resultStep() {
      return this.round?.resultStep
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.getRound()
      }
    }
  },
  methods: {
    getRound() {
      LottoService.getRound(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          this.round = response.data.round
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    },
    payWonMembers() {
      Swal.fire({
        title: 'ยืนยันจ่ายเงิน!',
        text: "ยอดจะถูกโอนเข้าบัญชีสมาชิก",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return LottoService.payWonMembers(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'จ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        console.log(result)
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'จ่ายเงินสำเร็จ',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    },
    findAgents() {
      Swal.fire({
        title: 'คำนวนยอด ได้-เสีย ของเอเย่นต์!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.findAgentWinLose(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'คำนวนยอดไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'คำนวนยอดสำเร็จ',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    },
    payAgents() {
      Swal.fire({
        title: 'ยืนยันจ่ายเงิน!',
        text: "ยอดจะถูกโอนเข้าบัญชีเอเย่นต์",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.payAgents(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'จ่ายเงินไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'จ่ายเงินสำเร็จ',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    },
    closeTickets() {
      Swal.fire({
        title: 'ปิดโพย!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.closeTickets(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ปิดโพยไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ปิดโพยเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    },
    closeItems(betType) {
      Swal.fire({
        title: 'ปิดเลข!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.closeItems(this.roundId, betType, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ปิดเลขไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ปิดเลขเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    },
    closeRound() {
      Swal.fire({
        title: 'ปิดงวดหวย!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.closeRound(this.roundId, this.uniqueCode).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'ปิดงวดไม่สำเร็จ'),
              'error'
            )
          })
          .finally(()=>{
            this.uniqueCode = cryptoRandomString({length: 12, type: 'alphanumeric'})
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'ปิดงวดเสร็จเรียบร้อย',
            'success'
          )
          .then(()=>{
            this.getRound()
          })
        }
      })
    }
  },
  mounted() {
    this.getRound()
  }
}
</script>
<style lang="scss" scoped>
.table {
  border: 0;

  thead {
    tr {
      th {
        font-size: 90%;
        text-align: center;
      }
    }
  }

  tr {
    th {
      border-top: 0;
      vertical-align: middle;
    }

    td {
      vertical-align: middle;
      font-size: 90%;
    }
  }

  tr {
    td:first-child,
    th:first-child {
      border-left: 0;
    }
    td:last-child,
    th:last-child {
      border-right: 0;
    }
  }
}
</style>
